import React from 'react'
import styled, { css } from 'styled-components'

// Components
import Layout from 'components/layout/Layout'
import CustomLink from 'components/shared/CustomLink'
import ViewPortAnimatedFingerprint from 'components/elements/FingerPrint'
import ParseContent from 'components/shared/ParseContent'

interface NotFoundPageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerDefault = styled.section<{ image: boolean }>`
  background: rgb(${(props) => props.theme.color.gradient.contrast.to});
  background: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 45%
  );
  position: relative;

  @media screen and (min-width: 992px) {
    height: 430px;
  }

  @media screen and (max-width: 991px) {
    ${({ image }) =>
      image
        ? css`
            height: 500px;
          `
        : css`
            height: 300px;
          `}
  }

  @media screen and (max-width: 575px) {
    ${({ image }) =>
      image
        ? css`
            height: 400px;
          `
        : css`
            height: 300px;
          `}
  }
`

const BannerBackground = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1440px;

  @media (min-width: 1439px) {
    left: calc(50vw - 770px);
  }

  & svg {
    opacity: 0.12;
    position: absolute;
    overflow-y: hidden;

    @media (min-width: 992px) {
      width: 800px;
      top: -150px;
      left: 50%;
    }

    @media (max-width: 991px) {
      width: 600px;
      top: -150px;
      left: 45%;
    }

    @media (max-width: 767px) {
      width: 400px;
      top: -250px;
      left: 30%;
    }
  }
`

const BannerWrapper = styled.div``

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-weight: ${(props) => props.theme.font.weight.regular};
    @media (min-width: 1199px) {
      line-height: ${(props) => props.theme.font.size.unit};
      font-size: ${(props) => props.theme.font.size.giga};
    }

    @media (max-width: 991px) {
      line-height: ${(props) => props.theme.font.size.giga};
      font-size: ${(props) => props.theme.font.size.mega};
    }

    @media (max-width: 767px) {
      line-height: ${(props) => props.theme.font.size.mega};
    }
  }
`

const BannerRow = styled.div`
  @media screen and (min-width: 992px) {
    height: 100%;
  }

  @media screen and (max-width: 991px) {
    padding-top: 40px;
  }
`
const NotFoundPage: React.FC<NotFoundPageProps> = () => (
  <Layout>
    <StyledBannerDefault image className="position-relative w-100">
      <BannerWrapper className="container h-100">
        <BannerRow className="row">
          <div className="col-lg-7 pt-5 d-flex align-items-center h-100 ">
            <Content content="<h1 class='text-white'>404</h1>" />
          </div>
        </BannerRow>
      </BannerWrapper>
      <BannerBackground>
        <ViewPortAnimatedFingerprint />
      </BannerBackground>
    </StyledBannerDefault>
    <div className="container py-5">
      <p>
        <span>
          Oei! De pagina kan niet gevonden worden. Ga terug naar de homepage
          door&nbsp;
        </span>
        <CustomLink to="/" className="text-underline">
          hier
        </CustomLink>
        <span> te klikken.</span>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
